import React, { useState } from 'react';
import './master.css';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import { useDispatch, useSelector } from 'react-redux';
import { selectFliterFunction } from '../../Redux/Reducer/filterSlice';
import { darkTheme, dayTheme } from '../../PageStyle/colorsdarkWhite';

function MasterAccount({ accountList, selectboxAttributes,searchValue,setSearchValue}) {
    const [searchQuery, setSearchQuery] = useState("");
    const dispatch = useDispatch();

    // Filter the account list based on the search query
    const filteredAccounts = accountList.filter((item) =>
        item?.name?.toLowerCase().includes(searchValue.toLowerCase())
    );

    const handleSelectAccountButton = (item) => {
        dispatch(selectFliterFunction({ filtername: item?.name , filterId: item?.id }));
        setSearchValue('');
    };
    const selectFilterName = useSelector((state) => state.Filternames)
    const darkModeState = useSelector((state) => state.darkMode);

    return (
        <div className='AccountMenuBgColor w-5/12 sm:hidden sm:h-[84%] lg:h-[84%] xl:h-[84%] overflow-y-auto mt-[95px] shadow'>
            <style>
                {
                    `
                    .accountButtonStyle{
                        background:white;
                        width:45px;
                        height:45px;
                        box-shadow: inset 6px 1px 4px 2px #bfbbbb;
                    }

                    .selectedAccoundButton{
                        background:${darkModeState.checkvalue ? dayTheme.DmenuButnColor : darkTheme.DmenuButnColor};
                        width:45px;
                        height:45px;
                        color:white;
                        box-shadow: inset 6px 1px 4px 2px #7e7e7e;
                    }
                    `
                }
            </style>
            <div className='flex justify-end py-2'>
                <input 
                    type="text" 
                    className='rounded outline-none pl-3' 
                    style={{fontSize:'8px'}}
                    placeholder="Search accounts"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <button className='ml-3'> 
                    <ManageSearchRoundedIcon className='text-white' style={{fontSize:'25px'}} />
                </button>
            </div>
            <div className='grid grid-cols-5'>
                {filteredAccounts.map((item) => (
                    <div key={item.id} className='divWidth flex flex-col items-center m-2'>
                        <button 
                            className={`${selectFilterName.filtername === item?.name ? 'selectedAccoundButton' : 'accountButtonStyle'} rounded py-2 font-black`} 
                            style={{fontSize: '15px'}}
                            onClick={() => handleSelectAccountButton(item)} // Add the onClick here
                        >
                            {item?.name?.substr(0, 2)}
                        </button>
                        <label className='text-center text-white font-bold' style={{fontSize: '10px'}}>
                            {item?.name?.substr(0, 8)}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MasterAccount;
