import { createSlice } from "@reduxjs/toolkit";

const masterGridSlice = createSlice({
    name:"masterGrid",
    initialState:{
        rows:[]
    },
    reducers:{
        setRows:(state,action) => {
            state.rows = action.payload;
        },
        clearRows:(state)=>{
            state.rows=[];
        }
    }
});

export const {setRows,clearRows} = masterGridSlice.actions;

export default masterGridSlice.reducer