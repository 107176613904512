import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMenuFunction } from '../../Redux/Reducer/activateMenuSlice';
import './comman.css';
import UpdateImg from '../../asset/alert icons/update.png';
import SaveImg from '../../asset/alert icons/save.png';
import DeleteImg from '../../asset/alert icons/delete.png';
import { darkTheme, dayTheme } from '../../PageStyle/colorsdarkWhite';

function AlertBoxMessage({ handleSave, setdisplayCustomAlertBox, handeleDeleteFunction }) {
    const selectState = useSelector((state) => state.selectMenu);
    const dispatch = useDispatch();
    const darkModeState = useSelector((state) => state.darkMode);
    const arabicAlignMent = useSelector((state) => state.arabicAlignMent);

    // Define localized messages and buttons based on text direction
    const localizedData = {
        ltr: {
            insert: {
                message: "Do You Want to Save?",
                buttons: ["Save", "Don't Save"]
            },
            update: {
                message: "Do You Want to Update?",
                buttons: ["Update", "Don't Update", "Cancel"]
            },
            delete: {
                message: "Do You Want to Delete?",
                buttons: ["Delete", "Cancel"]
            }
        },
        rtl: {
            insert: {
                message: "هل تريد الحفظ؟",
                buttons: ["حفظ", "لا تحفظ"]
            },
            update: {
                message: "هل تريد التحديث؟",
                buttons: ["تحديث", "لا تحديث", "إلغاء"]
            },
            delete: {
                message: "هل تريد الحذف؟",
                buttons: ["حذف", "إلغاء"]
            }
        }
    };

    const currentLocale = arabicAlignMent.rightToLeft === 'ltr' ? 'ltr' : 'rtl';
    const messages = localizedData[currentLocale];

    const AlertBoxDatas = [
        {
            name: "Insert",
            buttons: messages.insert.buttons,
            message: messages.insert.message,
            image: SaveImg
        },
        {
            name: "Update",
            buttons: messages.update.buttons,
            message: messages.update.message,
            image: UpdateImg
        },
        {
            name: "Delete",
            buttons: messages.delete.buttons,
            message: messages.delete.message,
            image: DeleteImg
        }
    ];

    return (
        <div className='absolute top-0 h-screen d-flex justify-center items-center xl:w-9/12 lg:w-9/12 sm:w-full'>
            {AlertBoxDatas.filter((filter) => filter.name === selectState.name).map((item) => (
                <div
                    key={item.name}
                    className='relative rounded-[30px] bg-gray-100 shadow xl:w-5/12 lg:w-6/12 sm:w-12/12 h-fit z-50 d-flex flex-col justify-center items-center py-3'
                    style={{
                        background: darkModeState.checkvalue ? dayTheme.masterListRowColor : darkTheme.masterListRowColor,
                        direction: currentLocale === 'ltr' ? 'ltr' : 'rtl', // Apply text direction
                        textAlign: currentLocale === 'ltr' ? 'left' : 'right' // Align text
                    }}
                >
                    <div className='w-full d-flex justify-center'>
                        <div>
                            <h1 className='text-center font-bold pt-3'>{currentLocale === 'ltr' ? 'Alert' : 'تنبيه'}</h1>
                            <h1 className='text-center py-2 font-black'>{item.message}</h1>
                            {item.buttons.map((arrButt) => {
                                let onClickHandler;
                                // Determine the onClick handler based on the button label
                                if (arrButt === (currentLocale === 'ltr' ? 'Save' : 'حفظ')) {
                                    onClickHandler = handleSave;
                                } else if (arrButt === (currentLocale === 'ltr' ? "Don't Save" : "لا تحفظ") || arrButt === (currentLocale === 'ltr' ? "Don't Update" : "لا تحديث")) {
                                    onClickHandler = () => dispatch(selectMenuFunction({ name: 'List', opid: selectState.opid }));
                                } else if (arrButt === (currentLocale === 'ltr' ? 'Cancel' : 'إلغاء') || arrButt === (currentLocale === 'ltr' ? 'Error' : 'خطأ')) {
                                    onClickHandler = () => setdisplayCustomAlertBox(false);
                                } else if (arrButt === (currentLocale === 'ltr' ? 'Delete' : 'حذف')) {
                                    onClickHandler = () => handeleDeleteFunction();
                                }

                                return (
                                    <button
                                        key={arrButt}
                                        className='alertBGColor text-white hover:bg-[#362180] px-3 py-1 shadow m-2 rounded-full font-bold'
                                        onClick={onClickHandler}
                                    >
                                        {arrButt}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                    <div className='absolute top-[-20px]'>
                        <img src={item.image} alt="" width={60} />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default AlertBoxMessage;
