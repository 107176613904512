import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  LoginPageStyle,
  NavBarComponentStyle,
} from "../../PageStyle/pageStyleVariable";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import logoutFolder from "../../../src/asset/Menusvg/main/logout.png";
import "../NavBar/NavMenu.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { darkTheme, dayTheme } from "../../PageStyle/colorsdarkWhite";
import axios from "axios";
import { BaseURL } from "../Masters/masterPagefunctions";
import CircularProgress from '@mui/material/CircularProgress';
export default function LogOutBox() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [beforLoad,setBeforLoad] = React.useState(false)

  const darkModeState = useSelector((state) => state.darkMode);
  const arabicAlignMent = useSelector((state) => state.arabicAlignMent);
  const responseObjectString = sessionStorage.getItem("responseobjerct");
  const resObject = JSON.parse(responseObjectString);  // Fixed this line
  const refresh_token = resObject.refresh_token;
 

  // const handleLogout = async () => {
  //   try {
  //     let access_token = sessionStorage.getItem("token");
  //     // Check if access token exists
  //     if (!access_token) {
  //       throw new Error("No access token found");
  //     }

  //     console.log("old access token:", access_token);
  
  
  //     // Send a POST request with the Bearer token in the headers
  //       await axios.post(
  //       `https://userhub.${BaseURL}/api/v1/logout`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`,  // Use the access token from sessionStorage
  //         },
  //       }
  //     );

  //     // Redirect to home or login page on successful logout
  //     // navigate("/");
  //   } catch (err) {
  //     console.log("Failed to logout", err);
  
  //     // If the response is unauthorized and retry is not yet attempted, refresh the token
  //     if (err.response && err.response.status === 401) {
  //       try {
  //         const responseObjectString = sessionStorage.getItem("responseobjerct");
  //         const resObject = JSON.parse(responseObjectString);
  //         const refresh_token = resObject.refresh_token;
  
  //         // Attempt to refresh the token
  //         const refreshResponse = await axios.post(
  //           `https://userhub.${BaseURL}/api/v1/refresh-token`,
  //           { refresh_token }
  //         );
  
  //         console.log("New access token:", refreshResponse.data.access_token);
  
  //         // Store the new access token in sessionStorage
  //         sessionStorage.setItem("token", refreshResponse.data.access_token);

  //         handleLogout()
  //       } catch (refreshErr) {
  //         console.log("Failed to refresh token", refreshErr);
  //       }
  //     }
  //   }
  // };


  const handleLogout = async () => {
    try {
      let access_token = sessionStorage.getItem("token");
  
      // Check if access token exists
      if (!access_token) {
        throw new Error("No access token found");
      }
  
      console.log("Access token before logout:", access_token);
  
      // Send a POST request with the Bearer token in the headers
      await axios.post(
        `https://userhub.${BaseURL}/api/v1/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,  // Use the access token from sessionStorage
          },
        }
      ).then(
        setBeforLoad(true)
      )
  
      // Clear the retry count after successful logout
      sessionStorage.removeItem("logoutRetryCount");
  
      // Redirect to home or login page on successful logout
      console.log("Logout successful. Redirecting...");
      navigate("/");
    } catch (err) {
      console.log("Failed to logout:", err);
  
      // Retrieve the current retry count from sessionStorage
      let retryCount = parseInt(sessionStorage.getItem("logoutRetryCount")) || 0;
  
      // If the response is unauthorized and retry count is below limit, attempt to refresh the token
      if (err.response && err.response.status === 401 && retryCount < 1) {
        try {
          console.log("Unauthorized, attempting token refresh...");
  
          const responseObjectString = sessionStorage.getItem("responseobjerct");
  
          if (!responseObjectString) {
            throw new Error("No response object found in sessionStorage");
          }
  
          const resObject = JSON.parse(responseObjectString);
          const refresh_token = resObject.refresh_token;
  
          if (!refresh_token) {
            throw new Error("No refresh token found");
          }
  
          // Attempt to refresh the token
          const refreshResponse = await axios.post(
            `https://userhub.${BaseURL}/api/v1/refresh-token`,
            { refresh_token }
          );
  
          console.log("New access token:", refreshResponse.data.access_token);
  
          // Store the new access token in sessionStorage
          sessionStorage.setItem("token", refreshResponse.data.access_token);
  
          // Increment the retry count and store it in sessionStorage
          retryCount += 1;
          sessionStorage.setItem("logoutRetryCount", retryCount.toString());
  
          // Retry the logout after token refresh
          await handleLogout();
        } catch (refreshErr) {
          console.log("Failed to refresh token:", refreshErr);
          setBeforLoad(false);
        }
      } else {
        console.log("Reached max retries or unhandled error:", err.message || err);
      }
    }
  };
  


  
  

  const style = {
    position: "absolute",
    top: "230px",
    right: "0",
    left: arabicAlignMent.rightToLeft === "ltr" ? "1" : "120px",
    transform: "translate(-50%, -50%)",
    width: 200,
    bgcolor: darkModeState.checkvalue
      ? dayTheme.masterListRowColor
      : darkTheme.masterListRowColor,
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };
console.log("left",style.left);
  // Conditional text based on language preference
  const message = arabicAlignMent.rightToLeft === "ltr"
    ? "Are you sure you want to log out?"
    : "هل أنت متأكد أنك تريد تسجيل الخروج؟";

  const okText = arabicAlignMent.rightToLeft === "ltr"
    ? "LogOut"
    : "تسجيل الخروج";

  const cancelText = arabicAlignMent.rightToLeft === "ltr"
    ? "Cancel"
    : "إلغاء";

  return (
    <div>
      <Button onClick={handleOpen}>
        <AccountCircleIcon
          className="mx-3"
          style={{
            color: NavBarComponentStyle.navBarUserColor,
            width: NavBarComponentStyle.NavBarSearchIconSize,
            height: NavBarComponentStyle.NavBarSearchIconSize,
          }}
        />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            className="flex justify-center item-center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <img
              src={logoutFolder}
              style={{ width: "65px" }}
              alt=""
            />
          </Typography>
          <div className="text-center font-semibold" sx={{ mt: 2 }}>
            {message}
          </div>
          <div>
            <button
              className="rounded-xl text-center py-1 my-1 font-semibold logOutbutton"
              style={{ width: "100%" }}
              onClick={handleLogout}
            >
              {okText} {beforLoad && <CircularProgress color="inherit" style={{width:'20px',height:'20px'}}/>}
            </button>

            <button
              className="rounded-xl text-center py-1 my-1 font-semibold logOutbutton"
              style={{ width: "100%" }}
              onClick={handleClose}
            >
              {cancelText}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
